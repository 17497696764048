import { apiAxios } from '@/utils/request'

// 购物车列表
export const cartList = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/cart/list', data, Istoast, Send)
}

// 添加到购物车
export const addToCart = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/cart/add', data, Istoast, Send)
}

// 从购物车删除
export const removeFromCart = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/cart/remove', data, Istoast, Send)
}

// 修改购物车项的数量
export const updateCartItemNum = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/cart/update_num', data, Istoast, Send)
}

// 修改购物车项的SKU
export const updateCartItemSku = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/cart/update_sku', data, Istoast, Send)
}
