<template>
  <div>
    <div class="shoppingCartContainer ">
      <a-breadcrumb class="mt40 mb25">
        <a-breadcrumb-item>
          <span class="font16 fc-333 ff-ss font-bold">Cart（{{ total || 0 }} Items）</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="shoppingCartContentView">
        <div class="contentTopView d-flex a-center">
          <div class="searchInput">
            <a-input-search v-model="searchValue" placeholder="Search Product" style="width: 300px" @change="research" />
          </div>
          <a-button :disabled="!hasSelected" :class="`bulkDeleteBtn ${hasSelected?'active':''} fs-14 ff-ss ml-auto`" @click="bulkDeleteCartItem">Bulk Delete</a-button>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :loading="loading"
          :data-source="carts"
          :pagination="false"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <div slot="Product" slot-scope="text, record" class="d-flex a-center tableProductView">
            <div class="productImg " @click="showProductImages([record.mainImageUrl])"><img class="productImg" :src="record.mainImageUrl"></div>
            <div class="productMessageView">
              <div class="fs-14 ff-ss primaryHover fontBold">{{ record.blankProductName }}</div>
              <option-info v-if="record.option1Name" :option-name="record.option1Name" :option-value="record.option1Value" />
              <option-info v-if="record.option2Name" :option-name="record.option2Name" :option-value="record.option2Value" />
              <option-info v-if="record.option3Name" :option-name="record.option2Name" :option-value="record.option3Value" />
            </div>
          </div>
          <!-- CustomFile -->
          <div slot="CustomFile" slot-scope="text, record" class="d-flex tableProductView">
            <div v-if="record.imageBoxInfoList && record.imageBoxInfoList.length > 0" class="productImg " @click="showProductImages([record.imageBoxInfoList[0].imageUrl])">
              <img class="productImg" :src="record.imageBoxInfoList[0].imageUrl">
            </div>
            <div class="productMessageView d-flex flex-column">
              <div v-if="record.inputBoxInfoList && record.inputBoxInfoList.length > 0" class="fs-14 ff-ss primaryHover">
                {{ record.inputBoxInfoList[0].text }}
              </div>
              <div v-if="(record.imageBoxInfoList && record.imageBoxInfoList.length > 0) || (record.inputBoxInfoList && record.inputBoxInfoList.length > 0)" class="primaryHover fs-14 ff-ss cursorPointer colorprimary mt-auto userSelectNone fontBold" @click="selectViewRecord(record)">
                View More
              </div>
            </div>
          </div>
          <!-- CustomFile end -->
          <div slot="quantity" slot-scope="text, record">
            <a-input-number id="inputNumber" v-model="record.quantity" :min="1" :max="9999" @change="handleCartItemNumChange(record)" />
          </div>
          <div slot="total" slot-scope="text, record">
            {{ record.quantity*record.price | MoneyFormat }}
          </div>
          <span slot="customAddlitinsCharge"><a-icon type="question-circle" />Additional charge</span>
          <span slot="action" slot-scope="text, record">
            <a-dropdown placement="bottomCenter">
              <div class="d-flex a-center actionsBtn plr10 j-sb colorgraylightColor">
                {{ "Actions" }}
                <a-icon type="down" class="actionsBtnicon" />
              </div>
              <a-menu slot="overlay">
                <a-menu-item>
                  <div @click="toEditVariant(record)"><div class="actionsBtnItem">Edit</div></div>
                </a-menu-item>
                <a-menu-item>
                  <div @click="toCreateOrder([record.id])"><div class="actionsBtnItem">Create an order</div></div>
                </a-menu-item>
                <a-menu-item>
                  <div @click="onCartItemDelete(record.id)"><div class="actionsBtnItem">Delete</div></div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <div class="paginationView pt15 d-flex a-center">
          <a-select value="10" class="actionsSelect">
            <a-select-option value="10">10</a-select-option>
            <a-select-option value="50">50</a-select-option>
            <a-select-option value="100">100</a-select-option>
          </a-select>
          <a-pagination
            class="ml-auto"
            :current="pageIndex"
            :page-size="pageSize"
            show-quick-jumper
            size="small"
            :total="total"
            @change="onPageIndexChange"
          />
        </div>
      </div>
      <div class="actionBtnView pt20 pb20 d-flex a-center">
        <div class="fs-14 fc-333 ff-ss ">
          Product price: $ {{ allTotalPrices | MoneyFormat }}
        </div>
        <a-button :disabled="!hasSelected" class="ml-auto checkoutBtn" type="primary" @click="onCheckout">{{ "Check out" }}</a-button>
      </div>
      <select-variants ref="toEditVariantDialog" @skuChange="onSkuChange" />
    </div>
    <look-imgs-modal
      :banner-list="previewImages"
      height="470"
      :visible.sync="showPreviewImage"
    />
    <record-view-modal :view-record-visible="viewRecordVisible" :view-record="viewRecord" @handleCancel="viewRecordVisible=false" />
  </div>
</template>

<script >
import SelectVariants from './components/SelectVariants'
import RecordViewModal from './components/RecordViewModal'
import { cartList, removeFromCart, updateCartItemNum, updateCartItemSku } from '@/api/cart.js'
import OptionInfo from './components/option_info'
import LookImgsModal from '@/views/my_products/components/look_imgs_modal'
const columns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    scopedSlots: { customRender: 'Product' }
  },
  {
    title: 'Custom File',
    dataIndex: 'customFile',
    key: 'customFile',
    scopedSlots: { customRender: 'CustomFile' }
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    scopedSlots: { customRender: 'total' }
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  components: {
    SelectVariants,
    OptionInfo,
    LookImgsModal,
    RecordViewModal
  },
  data() {
    return {
      selectVariantsVisible: false,
      pageIndex: 1,
      pageSize: 10,
      total: 3,
      searchValue: '',
      columns,
      selectedRowKeys: [],
      carts: [],
      loading: false,
      editVariantCartItemId: null,
      showPreviewImage: false,
      previewImages: [],
      viewRecord: undefined, // 当前预览的商品
      viewRecordVisible: false // 当前预览的商品弹框显示
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    allTotalPrices() {
      let count = 0
      this.carts.forEach(i => {
        this.selectedRowKeys.forEach(item => {
          if (item === i.id) {
            count += (i.price * i.quantity)
          }
        })
      })
      return count
    }
  },
  mounted() {
    this.research()
  },
  methods: {
    // 当前预览的商品
    selectViewRecord(record) {
      this.viewRecord = record
      this.viewRecordVisible = true
    },
    showProductImages(record) {
      this.previewImages = record
      this.showPreviewImage = true
    },
    research() {
      this.pageIndex = 1
      this.search()
    },
    async search() {
      this.loading = true
      try {
        var postData = {}
        postData.current = this.pageIndex
        postData.size = this.pageSize
        postData.keyword = this.searchValue
        const response = await cartList(postData)
        if (response.code === 200) {
          var carts = []
          if (response.data.records) {
            for (const record of response.data.records) {
              var cart = Object.assign({}, record)
              if (cart.option1Value) {
                cart.option1Value = JSON.parse(cart.option1Value)
              }
              if (cart.option2Value) {
                cart.option2Value = JSON.parse(cart.option2Value)
              }
              if (cart.option3Value) {
                cart.option3Value = JSON.parse(cart.option3Value)
              }
              carts.push(cart)
            }
          }
          this.carts = carts
          this.total = response.data.total || 0
        }
        this.selectedRowKeys = []
      } finally {
        this.loading = false
      }
    },
    toEditVariant(item) {
      this.editVariantCartItemId = item.id
      this.$refs.toEditVariantDialog.show(item.blankProductId)
    },
    async onSkuChange(skuId) {
      this.loading = true
      try {
        var postData = {}
        postData.id = this.editVariantCartItemId
        postData.skuId = skuId
        await updateCartItemSku(postData)
        this.search()
      } finally {
        this.loading = false
      }
    },
    onCartItemDelete(id) {
      var _this = this
      this.$confirm({
        title: 'Delete?',
        content: 'Are you sure you want to delete these?',
        okText: 'Delete',
        cancelText: 'cancel',
        onOk() {
          _this.doCartItemDelete([id])
        }
      })
    },
    async doCartItemDelete(ids) {
      this.loading = true
      try {
        var postData = {}
        postData.ids = ids
        await removeFromCart(postData)
        this.search()
      } finally {
        this.loading = false
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    onPageIndexChange(page, pageSize) {
      console.log('page', page)
      console.log('pageSize', pageSize)
      this.pageIndex = page
    },
    onCheckout() {
      this.toCreateOrder(this.selectedRowKeys)
    },
    toCreateOrder(ids) {
      this.callNewPage(`/cart/confirm_order/${ids.join(',')}`)
    },
    handleCartItemNumChange(item) {
      var postData = {}
      postData.id = item.id
      postData.quantity = item.quantity
      updateCartItemNum(postData)
    },
    bulkDeleteCartItem() {
      // 批量删除
      var _this = this
      this.$confirm({
        title: 'Delete?',
        content: 'Are you sure you want to delete these?',
        okText: 'Delete',
        cancelText: 'cancel',
        onOk() {
          _this.doCartItemDelete(_this.selectedRowKeys)
        },
        onCancel() {}
      })
    }
  }
}

</script>

<style lang="less" scoped>
  .actionsBtnItem {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
}

      /deep/  .ant-dropdown-menu-item-active{
      color: #4cbbf9;
      background: #fff;
      .actionsBtnItem{
        background: #f5f8fa !important;
        border-radius: 12px;
      }
    }
.ant-dropdown-trigger{
  .actionsBtnicon{
    transition: all 0.2s ease-in;
  }
}
.ant-dropdown-open{
    .actionsBtnicon{
      transform: rotate(180deg);
      transition: all 0.2s ease-in;
    }
}
.actionsBtn {
  cursor: pointer;
  width: 140px;
  height: 39px;
  border-radius: 8px;
  background: #f5f8fa;
}
.shoppingCartContainer{
  width: 1400px;
  margin: 0 auto;

  .fs-16{font-size: 16px;}
  .fs-14{font-size: 14px;}
  .ff-ss{font-family: Segoe UI-Regular, Segoe UI;}
  .fw-400{font-weight: 400;}
  .fc-333{color: #333333;}
  .fc-999{color: #999999;}
  .h-auto{height: auto !important;}
  .shoppingCartTitle{
    padding-top: 40px;
    padding-bottom: 24px;
  }
  .shoppingCartContentView{
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    .contentTopView{
      padding-bottom: 20px;
      .searchInput{
      }
      .bulkDeleteBtn{
        color: #999999;
        background: #F5F8FA;
        border: none;
        &.active{
          background: #009ef7 !important;
      color: #fff !important;
        }
      }
    }
    .actionsSelect {
      width: 140px;
    }
  }
  .tableProductView{
    .productImg{
      width: 77px;
      height: 77px;
      border-radius: 8px;
    }
    .productMessageView{
      padding-left: 14px;
      .smallImg{
        width: 30px;
        height: 30px;
        border-radius: 4px;
      }
    }
  }
  .actionBtnView{
    .checkoutBtn{
      width: 202px;
    }
  }

  /deep/ .ant-table-thead > tr > th{
    background-color: transparent;
    border-bottom: 1px solid #EEEEEE;
  }
  /deep/ .ant-table-tbody > tr > td{
    background-color: transparent !important;
    border-bottom: 1px solid #EEEEEE !important;
  }
  /deep/ .ant-table-wrapper {
    border-bottom: none;
  }
}
</style>
