<template>
  <div>
    <div v-if="optionValue.fileUrl" class="pt5 pb5 fc-999">
      <img class="smallImg" :src="optionValue.fileUrl">
      <span class="font12 pl5">{{ optionName }}:{{ optionValue.text }}</span>
    </div>
    <div v-else class="fc-999 font12">
      {{ optionName }}:{{ optionValue.text }}
    </div>
  </div>

</template>

<script>
export default {
  name: 'OptionInfo',
  props: {
    optionName: {
      type: String,
      default: null
    },
    optionValue: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.fc-999{color: #999999;}
.smallImg{
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
</style>
