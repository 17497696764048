<template>
  <a-modal
    :title="false"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :ok-text="'confirm'"
    :cancel-text="'cancel'"
    @ok="handleOk"
    @cancel="visible = false"
  >
    <a-spin :spinning="loading" />
    <div class="selectVariantsContainer">
      <div class="content-view">
        <div class="contentTitle font-bold font16 ff-ss pb10">Select variants</div>
        <!-- <select-variants-content -->
        <div class="selectorContent">
          <sku-selector
            v-if="visible"
            ref="sku-selector"
            :skus="skuInfos"
            :option1-name="blankProductInfo.skuOption1"
            :option1-type="blankProductInfo.skuOption1Type"
            :option2-name="blankProductInfo.skuOption2"
            :option2-type="blankProductInfo.skuOption2Type"
            :option3-name="blankProductInfo.skuOption3"
            :option3-type="blankProductInfo.skuOption3Type"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script >
import { productDetail } from '@/api/product'
import SkuSelector from '@/components/sku_selector'
// import SelectVariantsContent from '@/components/SelectVariantsContent'
export default {
  name: 'SelectVariants',
  components: {
    // SelectVariantsContent
    SkuSelector
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      visible: false,
      skuInfos: [],
      blankProductInfo: {}
    }
  },
  methods: {
    handleOk() {
      this.visible = false
      const skuId = this.getSku()
      if (skuId) {
        this.$emit('skuChange', skuId)
      }
    },
    getSku() {
      return this.$refs['sku-selector'].getSkuId()
    },
    async getProductInfo(id) {
      var postData = {}
      postData.id = id
      postData.skuInfos = true
      postData.blankProductInfo = true
      const { data } = await productDetail(postData)
      return data
    },
    async show(blankProductId) {
      this.visible = true
      this.loading = true
      try {
        var { skuInfos, blankProductInfo } = await this.getProductInfo(blankProductId)
        this.skuInfos = skuInfos || []
        this.blankProductInfo = blankProductInfo || {}
      } finally {
        this.loading = false
      }
    }
  }
}

</script>

<style lang="less" scoped>
.selectVariantsContainer{
    .selectorContent{
      margin-top: -3vw;
    }

    .fs-16{font-size: 16px;}
    .fs-14{font-size: 14px;}
    .ff-ss{font-family: Segoe UI-Regular, Segoe UI;}
    .fw-400{font-weight: 400;}
    .fc-333{color: #333333;}
    .fc-999{color: #999999;}
    .h-auto{height: auto !important;}
    .content-view{
      .contentTitle{
        width: 100%;
        text-align: center;
      }
      .contentItem{
        .valueItemView{
          display: flex;
          flex-wrap: wrap;
          .value-item{
            cursor: pointer;
            width: 130px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            margin-bottom: 24px;
            border-radius: 5px;
            .colorIcon{
              width: 14px;
              height: 14px;
              border-radius: 50%;
            }
          }
          .valueItemNoSelect{
            border: 1px solid #DDDDDD;
            background-color: #ffffff;
            color: #333333;
            transition: all .3s;
          }
          .valueItemSelect{
            border: 1px solid #F2F6FF;
            background-color: #F2F6FF;
            color: #009EF7;
            transition: all .3s;
          }
        }
      }
    }
}
</style>
