import { render, staticRenderFns } from "./RecordViewModal.vue?vue&type=template&id=168cfc78&scoped=true"
import script from "./RecordViewModal.vue?vue&type=script&lang=js"
export * from "./RecordViewModal.vue?vue&type=script&lang=js"
import style0 from "./RecordViewModal.vue?vue&type=style&index=0&id=168cfc78&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168cfc78",
  null
  
)

export default component.exports